import * as React from 'react';
import { useRouteData } from 'react-static';
import { SearchOptions, SearchType } from '../../common/d7/common';
import { getSearchResults } from '../../common/d7/search';
import { useDebug } from '../../common/hooks/useDebug';
import { useSearchResults } from '../../common/hooks/useSearchResults';
import { useTranslations } from '../../common/translation';
import { BannerCarousel } from '../../components/banner-carousel/BannerCarousel';
import { FeaturedBanner } from '../../components/featured-banner/FeaturedBanner';
import { HeadTitle } from '../../components/head-title/HeadTitle';
import { InstagramLoader } from '../../components/instagram-loader/InstagramLoader';
import { NoticeMessage } from '../../components/notice/Notice';
import { RowHeader } from '../../components/row-header/RowHeader';
import { BrandResults } from './brand-results/BrandResults';
import { BrandLayoutProps } from './BrandLayout.props';
import { StyledBrandLayout } from './BrandLayout.styles';
import translations from './translations';

// noinspection JSUnusedGlobalSymbols
export default () => {
  const {
    bottomBannerProps,
    image,
    locale,
    brandTitleIds,
    path,
    subtitle,
    title,
    bannerTitle,
    notice,
    translatedPaths,
    seo,
  } = useRouteData<BrandLayoutProps>();
  const { t } = useTranslations(translations, locale);
  const type = SearchType.getNodes;
  const options: SearchOptions = {
    type,
    locale,
  };
  const { isLoading, searchResults } = useSearchResults(brandTitleIds, getSearchResults, options);
  const featuredBannerProps = {
    image: { alt: image.alt, src: image.src },
    titleProps: {
      title,
      addlTitleData: {
        marketingMessage: subtitle,
      },
    },
  };

  useDebug('app:layout:BrandLayout', { props: { image, locale, path, subtitle, title, translatedPaths, seo } });

  return (
    <StyledBrandLayout locale={locale} seo={seo} translatedPaths={translatedPaths}>
      <HeadTitle title={`${title} | ${t('pageTitle', 'Brands')}`} />
      <NoticeMessage notice={notice} />
      <FeaturedBanner headingTag='h1' {...featuredBannerProps} />
      {isLoading && <InstagramLoader />}
      {!isLoading && searchResults.length > 0 && <BrandResults nodes={searchResults} />}
      {bannerTitle && <RowHeader headingTag='h2' title={bannerTitle} />}
      <BannerCarousel {...bottomBannerProps} addTopMargin={!bannerTitle} lazyLoad={true} />
    </StyledBrandLayout>
  );
};
