import * as React from 'react';
import { NormalizedSearchResult } from '../../../common/d7/common';
import { getImageRenditionRatio, ImageRenditionPresets, imageRenditionURL } from '../../../common/image-rendition';
import { useTranslations } from '../../../common/translation';
import { TitleCarousel } from '../../../components/title-carousel/TitleCarousel';
import { TitleCarouselNode } from '../../../components/title-carousel/TitleCarousel.props';
import translations from '../translations';
import { BrandResultsProps } from './BrandResults.props';

const buildCarouselNodes = (nodes: NormalizedSearchResult[]): TitleCarouselNode[] =>
  nodes.map((node) => {
    const irsPreset = node.isMobileGameOrApp
      ? ImageRenditionPresets.MobileAppGamesSquare
      : ImageRenditionPresets.KeyArt;
    const imageSrc = node.image?.src ? imageRenditionURL(node.image.src, irsPreset) : '';

    return {
      isMobileGameOrApp: node.isMobileGameOrApp,
      image: {
        src: imageSrc,
        alt: node.title,
      },
      imageRatio: getImageRenditionRatio(irsPreset),
      marketingMessage: node.marketingMessage,
      title: node.title,
      type: node.contentType,
      url: node.url,
    };
  });

export const BrandResults = ({ nodes }: BrandResultsProps) => {
  if (!(nodes || []).length) {
    return null;
  }
  const { t } = useTranslations(translations);
  const moviesNodes = nodes.filter((node) => node.contentType === 'Movies');
  const moviesCarouselNodes = buildCarouselNodes(moviesNodes);

  const tvNodes = nodes.filter((node) => node.contentType === 'TV Series' || node.contentType === 'TV Seasons');
  const tvCarouselNodes = buildCarouselNodes(tvNodes);

  const gamesNodes = nodes.filter((node) => node.contentType === 'Games & Apps');
  const gamesCarouselNodes = buildCarouselNodes(gamesNodes);

  return (
    <>
      <TitleCarousel lazyLoad={true} nodes={moviesCarouselNodes} title={t('movies', 'Movies')} />
      <TitleCarousel lazyLoad={true} nodes={tvCarouselNodes} title={t('tvShows', 'TV Shows')} />
      <TitleCarousel lazyLoad={true} nodes={gamesCarouselNodes} title={t('gamesAndApps', 'Games & Apps')} />
    </>
  );
};
