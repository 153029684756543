import styled from 'styled-components';
import { mediaQueryWidth } from '../../common/breakpoints';
import { StyledInstagram } from '../../components/instagram-loader/InstagramLoader.styles';
import { DefaultLayout } from '../default/DefaultLayout';

export const StyledBrandLayout = styled(DefaultLayout)`
  ${StyledInstagram} {
    padding: 32px 20px;

    ${mediaQueryWidth({ min: 'mobile' })`
      padding: 32px 44px;
    `}
`;
